import Logo from "../../images/icons/logotype.png"
import BurgerOpen from "../../images/icons/mob-burger-open.svg"
import BurgerClose from "../../images/icons/mob-burger-close.svg"

import tg_ru from '../../images/icons/tg_ru.svg'
import tg_en from '../../images/icons/tg_en.svg'
import x from '../../images/icons/x.svg'
import discord from '../../images/icons/discord.svg'

import { NavLink } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import Wallet from "./wallet/Wallet"
import { useDisconnect } from "wagmi"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setMobBurger } from "./Header.slice"



const Header = () => {
  // const mobBurgerOpen = false;
  const { disconnectAsync } = useDisconnect()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const mobBurger = useAppSelector(state => state.mobBurger.open)
  // const [mobBurger, setMobBurger] = useState<boolean>(false)


  const handleDisconnect = async () => {
    await disconnectAsync()
  }

  return (

    <header className="fixed mt-5 z-40 w-full">
      <div className={`${mobBurger ? 'mob_element_close' : 'mob_element_open'} wrapper w-full`}>

        <div id="header" className=" justify-between p-4 flex items-center bg-lightGray rounded-[20px] text-white">

          <div className="gap-5 flex flex-col w-fit max-w-[15%] max-[1050px]:max-w-[fit-content] max-w-[1200px]:hidden">
            <NavLink to="https://pac-staking.com" className="w-fit hover:text-textGray">
              <img src={Logo} alt="logotype" className="max-w-[120px] max-[600px]:max-w-[95px]" />
              {/* {import.meta.env.VITE_APP_DEVELOPMENT ?? ''} */}

            </NavLink>

          </div>

          <div className="flex flex-row gap-6 justify-center font-extrabold items-center lg:md:text-base text-sm font-orbitron max-[1200px]:hidden">
          <NavLink to='https://octa.pac-staking.com' target="_blank" className={({ isActive }) => isActive ? 'text-yellow' : 'hover:text-textGray'}>
              PAC 5000
            </NavLink>
            <NavLink
              to="https://pac10.pac-staking.com/"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
              target="_blank"
            >
              PAC 10000
            </NavLink>

            <NavLink
              to="/"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              PAC SOLO
            </NavLink>

            <NavLink
              to="https://octa200.pac-staking.com/"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              OCTA 200
            </NavLink>

            <NavLink
              target="_blank"
              to="https://octa1000.pac-staking.com/"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              OCTA 1000
            </NavLink>

            <NavLink
              to="https://redev2.pac-staking.com/"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
              target="_blank"
            >
              REDEV2
            </NavLink>
          </div>
          <div className="flex flex-row justify-end gap-4 max-[1200px]:hidden">

            <Wallet padding="p-2" />

          </div>
          <div id="burger_open" className="min-[1200px]:hidden">
            <button
              onClick={() => {
                dispatch(setMobBurger())
              }}
              className="yellow_icon_btn"

            >
              <img src={BurgerOpen} alt="Open burger" />
            </button>
          </div>

        </div>

      </div>



      <div id="mob_menu" className={`${mobBurger ? 'mob_element_open' : 'mob_element_close'}`}>
        <div className="flex flex-col gap-[50px] overflow-auto">
          <div className="flex justify-between items-start ">
            <NavLink to="https://pac-staking.com" className="w-fit">
              <img src={Logo} alt="logotype" className="max-w-[95px]" />
            </NavLink>
            <button
              onClick={() => {
                dispatch(setMobBurger())
              }}
              className="pl-4 mt-2"
            >
              <img src={BurgerClose} alt="Close burger" />
            </button>
          </div>
          <div className="flex gap-2 justify-between w-full">
            <Wallet padding="p-3" />
          </div>
          <div className="flex flex-col gap-4 text-white font-orbitron pl-4">
          <NavLink to='https://octa.pac-staking.com' target="_blank" className={({ isActive }) => isActive ? 'text-yellow' : 'hover:text-textGray'}>
              PAC 5000
            </NavLink>
            <NavLink
              to="https://pac10.pac-staking.com"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
              target="_blank"
            >
              PAC 10000
            </NavLink>

            <NavLink
              to="/"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              PAC SOLO
            </NavLink>

            <NavLink
              to="https://octa200.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              OCTA 200
            </NavLink>

            <NavLink
              target="_blank"
              to="https://octa1000.pac-staking.com"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              OCTA 1000
            </NavLink>

            <NavLink
              to="https://redev2.pac-staking.com/"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
              target="_blank"
            >
              REDEV2
            </NavLink>

          </div>
        </div>

        <div className="flex flex-row justify-start gap-3">

          <a className="yellow_icon_btn" href="">
            <img src={tg_ru} alt="telegram ru" />
          </a>
          <a className="yellow_icon_btn" href="">
            <img src={tg_en} alt="telegram en" />
          </a>
          <a className="yellow_icon_btn" href="">
            <img src={discord} alt="discord" />
          </a>
          <a className="yellow_icon_btn" href="">
            <img src={x} alt="social X" />
          </a>

        </div>

      </div>

    </header>

  )
}

export default Header



{/* SOCIAL AND OLD BUTTON(SPAN) EXIT */ }
{/* <div className="flex flex-row justify-between">
  <div className="flex flex-row align-middle gap-2">
    <a target="_blank" href="https://discord.gg/JFwaENGDxy">
      <img src={Discord} alt="Discord" />
    </a>
    <a target="_blank" href="https://t.me/PacmanCoinRU">
      <img src={TelegramRU} alt="TelegramRU" />
    </a>
    <a target="_blank" href="mailto:coin.pacman@gmail.com">
      <img src={Mail} alt="Mail" />
    </a>
    <a target="_blank" href="https://twitter.com/PACMan_Token">
      <img src={X} alt="X" />
    </a>
    <a target="_blank" href="https://t.me/PacmanCoinMain">
      <img src={TelegramEN} alt="TelegramEN" />
    </a>
  </div>
  <span
    onClick={() => {
      handleDisconnect()
      tokenService.removeUser()
      navigate("/login")
    }}
    className="text-yellow text-xl cursor-pointer"
  >
  
</div> */}