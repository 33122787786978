import { Outlet, useNavigate } from "react-router-dom";
import Header from "./features/header/Header"
import Footer from "./features/footer/Footer";
function Layout() {
  const navigate = useNavigate();

  function requestPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (result) {
        // Поддержка устаревшей версии с функцией обратного вызова.
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    })
      .then(function (permissionResult) {
        if (permissionResult !== 'granted') {
          throw new Error('Permission not granted.');
        }
      });
  }
  requestPermission()
  console.log('APP IS RUNNING IN', import.meta.env.VITE_APP_VERSION, 'MODE');


  return (
    <>

      <main className="text-lg w-full h-dvh flex  flex-col justify-between">
        <Header />
        <Outlet />
        <Footer />
      </main>
    </>
  )


}

export default Layout;