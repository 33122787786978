
import 'swiper/css';
import { useFundStore } from "../../../scripts/Staking";
import { BigNumber } from "ethers";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PoolsType, setAvailableTickets, setTotalTickets } from '../../../app/slices/Rede.slice';
import Loader from '../../../helpers/Loader';
import { memo, useState } from 'react';
// import { IHistory, ITournamentsActiveAndHistory } from "../../"
import { Popover } from 'flowbite-react';
import Wallet, { IAccount } from '../../header/wallet/Wallet';
import { Circle, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import plus from '../../../images/icons/plus.svg'
import { changeChain } from '../../header/wallet/meta/chainHelper';
import { DEFAULT_CHAINID } from '../../../config';
import { useAccount } from 'wagmi';



const PacSolo = memo(() => {
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const myStore = new useFundStore();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  myStore.getAmountOfLeftIds().then((localAllLeftTickets) => {
    dispatch(setAvailableTickets(Number(localAllLeftTickets)))
    myStore.getTotalNodes().then(responce => {
      dispatch(setTotalTickets(Number(responce)))
      setIsLoading(false)
    })
  })

  const account = useAccount({
    onConnect({ address, connector, isReconnected }: IAccount) {
      console.log("Connected", { address, connector, isReconnected })
    },
  })

  const [buyLoading, setBuyLoading] = useState<boolean>(false)
  const [sellLoading, setSellLoading] = useState<boolean>(false)

  const [currentPool, setCurrentPool] = useState<PoolsType>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { total_tickets, available_tickets, all_user_tickets, nodes, nodesStatus, maxTicketInPool, user_address } = useAppSelector(state => state.RedeSlice)

  const Theme = {
    "root": {
      "base": "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
      "show": {
        "on": "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
        "off": "hidden"
      },
      "sizes": {
        "sm": "max-w-sm",
        "md": "max-w-md",
        "lg": "max-w-lg",
        "xl": "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
        "5xl": "max-w-5xl",
        "6xl": "max-w-6xl",
        "7xl": "max-w-7xl"
      },
      "positions": {
        "top-left": "items-start justify-start",
        "top-center": "items-start justify-center",
        "top-right": "items-start justify-end",
        "center-left": "items-center justify-start",
        "center": "items-center justify-center",
        "center-right": "items-center justify-end",
        "bottom-right": "items-end justify-end",
        "bottom-center": "items-end justify-center",
        "bottom-left": "items-end justify-start"
      }
    },
    "content": {
      "base": "relative h-full w-full p-4 h-auto",
      "inner": "relative rounded-3xl bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh]"
    },
    "body": {
      "base": "lg:md:p-6 p-4 pt-0 flex-1 overflow-auto justify-center",
      "popup": ""
    },
    "header": {
      "base": "flex items-start justify-between rounded-t-2xl dark:border-gray-600 border-b",
      "popup": "lg:md:p-6 p-4 border-b-0 pb-0",
      "title": "mb-5 lg:md:text-[32px] text-2xl font-orbitron  text-yellow font-bold text-center",
      "close": {
        "base": "ml-auto inline-flex items-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
        "icon": "h-5 w-5 text-white"
      }
    },
    "footer": {
      "base": "flex items-center space-x-2 rounded-b-2xl border-gray-200 lg:md:p-6 p-4 pt-0 dark:border-gray-600",
      "popup": "border-t"
    }
  }

  const handleStake = async () => {
    setBuyLoading(true)
    // await myStore.setUserStakes().then(res => console.log('test res: ', res))
    try {
      let promise = new Promise(async (resolve, reject) => {
        await myStore.stake()
          .then(() => {
            setTimeout(() => {
              navigate(0)
            }, 2000);
            resolve(true)
          })
          .catch(() => {
            reject('Something went wrong!')
          })
          .finally(() => {
            setBuyLoading(false)
          })
      })

      toast.promise(promise, {
        success: { title: 'Success', description: 'Node has been successfully created', position: "top-right", },
        error: { title: 'Error', description: 'Error occurred while creating the node. Please try again later.', position: "top-right", duration: 2000 },
        loading: { title: 'Pending', description: 'Waiting for transaction confirmation...', position: "top-right", },
      })
    } catch (e) {
      alert("There are not enough coins in your account");
      console.log(e);
    }
  }



  const handleUnstake = async (id: Number) => {
    setBuyLoading(true)
    try {
      let promise = new Promise(async (resolve, reject) => {
        await myStore.unstake(BigNumber.from(id))
          .then(() => {
            setTimeout(() => {
              navigate(0)
            }, 2000);
            resolve(true)
          })
          .catch((err) => {
            console.log(err);

            reject('Something went wrong!')
          })
          .finally(() => {
            setBuyLoading(false)
          })
      })

      toast.promise(promise, {
        success: { title: 'Success', description: 'Node was successfully disconnected', position: "top-right", },
        error: { title: 'Error', description: 'Error occurred while disconnecting the node. Please try again later.', position: "top-right", duration: 2000 },
        loading: { title: 'Pending', description: 'Waiting for transaction confirmation...', position: "top-right", },
      })
    } catch (e) {
      alert("There are not enough coins in your account");
      console.log(e);
    }
  }

  const handleGetReward = async (id: Number) => {
    try {
      let promise = new Promise(async (resolve, reject) => {
        await myStore.getRewards(BigNumber.from(id))
          .then(() => {
            setTimeout(() => {
              navigate(0)
            }, 2000);
            resolve(true)
          })
          .catch(() => {
            reject('Something went wrong!')
          })
          .finally(() => {
            setBuyLoading(false)
          })
      })

      toast.promise(promise, {
        success: { title: 'Success', description: 'Reward has been successfully received', position: "top-right", },
        error: { title: 'Error', description: 'Error occurred receiving the reward. Please try again later.', position: "top-right", duration: 2000 },
        loading: { title: 'Pending', description: 'Waiting for transaction confirmation...', position: "top-right", },
      })
    } catch (e) {
      console.log(e);
    }
  }


  function convertDate(dateStr: string) {
    const [dateparts, timeparts] = dateStr.split(",");
    const [year, month, day] = dateparts.split("-");
    const [hours = 0, minutes = 0, seconds = 0] = timeparts?.split(":") ?? [];
    // Treats the string as UTC, but you can remove the `Date.UTC` part and use
    // `new Date` directly to treat the string as local time
    var offset = new Date().getTimezoneOffset();
    let res = new Date(Date.UTC(+year, +month - 1, +day, +hours, +minutes + offset, +seconds));
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfWeek = days[res.getDay()];

    const newDateStr = dayOfWeek + ', ' + dateStr
    console.log(newDateStr);
    return newDateStr;
  }

  const getDayNTime = (dateStr: string) => {
    const [dateparts, timeparts] = dateStr.split(",");
    const [year, month, day] = dateparts.split("-");
    const [hours = 0, minutes = 0, seconds = 0] = timeparts?.split(":") ?? [];
    // Treats the string as UTC, but you can remove the `Date.UTC` part and use
    // `new Date` directly to treat the string as local time
    var offset = new Date().getTimezoneOffset();
    let res = new Date(Date.UTC(+year, +month - 1, +day, +hours, +minutes + offset, +seconds));
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfWeek = days[res.getDay()];

    const newDateStr = dayOfWeek + ', ' + dateStr.split(',')[1]
    console.log(newDateStr);
    return newDateStr;
  }





  return (
    <div className="background-image-yellow w-screen">
      <div className="wrapper-content ">
        <div className="flex flex-col lg:md:gap-12 gap-8 justify-between ">

          <div className='flex flex-col gap-4'>
            <h1 className="font-orbitron lg:md:w-3/5 w-full text-yellow lg:md:text-8xl sm:text-6xl text-[40px] leading-10 font-extrabold">SOLO Staking
              50 000</h1>
            <div className="lg:md:w-1/2 w-full sm:w-4/5 font-medium lg:md:text-3xl sm:text-xl text-base text-white">
              Connect and disconnect nodes from server pool. Get income from your investments!
            </div>
          </div>
          {/* <div className='text-white'>
            {Math.floor((new Date().getTime() - new Date('2024-03-29, 06:22').getTime()) / 60000 / 60 / 24)}
            {new Date(new Date('2024-03-29, 06:22'.replace(', ', 'T'))).toDateString()}
          </div> */}
          <div className="lg:md:mt-14 sm:mt-7 ">
            <div className=" flex lg:md:flex-row flex-col lg:md:gap-36 gap-8 justify-between">
              <div className="flex flex-col lg:md:gap-8 gap-4">
                <div className="flex flex-row lg:text-5xl md:text-4xl sm:text-3xl text-2xl text-white font-orbitron font-bold">Total nodes</div>
                <div className="flex flex-row lg:text-8xl md:text-7xl sm:text-6xl text-[45px] leading-10 text-yellow font-orbitron font-extrabold">{isLoading ? <Loader /> : `${total_tickets} nodes`}</div>
              </div>
              <div className="flex flex-col lg:md:gap-8 gap-4">
                <div className="flex flex-row lg:text-5xl md:text-4xl sm:text-3xl text-2xl text-white font-orbitron font-bold">Available nodes</div>
                <div className="flex flex-row lg:text-8xl md:text-7xl sm:text-6xl text-[45px] leading-10 text-yellow font-orbitron font-extrabold">{isLoading ? <Loader /> : `${available_tickets} nodes`}</div>
              </div>
            </div>
            <div className={`flex lg:md:sm:flex-row flex-col gap-6 justify-between lg:md:sm:items-center mt-20`}>
              <div className="lg:text-8xl md:text-6xl text-[40px] text-yellow font-extrabold font-orbitron">Your nodes</div>
              {
                user_address && nodesStatus == 'fulfilled' &&

                <div className="flex flex-col gap-2 self-end items-end">
                  <button className='yellow_btn flex flex-row items-center py-3 px-4 text-base rounded-2xl gap-3' onClick={() => handleStake()}>
                    Connect node
                    <img src={plus} alt="" />
                  </button>
                 
                </div>
              }
            </div>
            {/* {
              (poolStatus == 'fulfilled') && ''
            } */}
            {
              (nodesStatus == 'fulfilled') &&
              <div className={`lg:md:mt-14 sm:mt-10 mt-8 flex flex-col gap-8 `}>
                {
                  nodes && nodes?.length > 0 ? nodes.map((item) => {
                    return (
                      <div key={Number(item.id)} className='flex flex-col justify-between lg:gap-8 md:gap-6 sm:gap-4 gap-3'>
                        <div className='flex flex-row justify-between gap-4 max-[1300px]:flex-col max-[1300px]:w-full max-[1300px]:bg-lightGray max-[1300px]:p-3 max-[1300px]:rounded-2xl'>
                          <div className='flex lg:md:flex-row flex-col w-1/2 max-[1300px]:w-full justify-between lg:md:items-center  bg-lightGray lg:p-8  p-0 rounded-2xl'>
                            <span className='lg:text-[32px] md:text-[26px] text-[22px] text-yellow'>
                              Node #{Number(item.id)}
                            </span>
                            <span className='font-orbitron lg:text-2xl md:text-xl text-base text-white'>
                              {convertDate(item.start)}
                            </span>
                          </div>
                          <div className='flex flex-row lg:md:gap-4 sm:gap-3 gap-2 max-[1300px]:w-full w-1/2 '>
                            {Math.floor((new Date().getTime() - new Date(item.start.replace(', ', 'T')).getTime()) / 60000 / 60 / 24) < 30 ?
                              <div className='flex flex-row relative font-orbitron w-full'>
                                <button disabled={true} className='w-full disabled:opacity-50 lg:md:bg-lightGray bg-lightGrayHover px-4 font-orbitron text-white lg:text-3xl md:text-xl sm:text-base text-sm rounded-2xl transition duration-150 ease-out hover:ease-in'>
                                  Disconnect
                                </button>
                                <Popover
                                  theme={{
                                    "base": "absolute z-20 inline-block w-max max-w-[100vw] bg-white outline-none border border-gray-200 rounded-2xl shadow-sm dark:border-gray-600 dark:bg-gray-800",
                                    "content": "w-full h-fit  border-yellow border-0 bg-lightGray rounded-2xl text-sm text-gray-500 dark:text-gray-400",
                                    "arrow": {
                                      "base": "absolute h-2 w-2 z-1 rotate-45 mix-blend-lighten bg-lightGray border border-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:mix-blend-color",
                                      "placement": "-3px"
                                    }
                                  }}
                                  content={
                                    <div className="w-fit text-white lg:max-w-[440px] max-w-[300px] h-fit lg:md:p-3 p-2 text-center border-yellow border-2 bg-lightGray rounded-xl lg:text-xl md:text-lg sm:text-base text-sm text-gray-500 dark:text-gray-400">
                                      You will be able to disconnect the node a month after the connection
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <Circle bg='yellow' color='black' className='absolute lg:md:-top-4 lg:md:-right-4 -top-3 lg:md:w-10 w-6 lg:md:h-10 h-6 cursor-pointer lg:text-2xl md:text-xl text-base hover:bg-hoverYellow'>
                                    ?
                                  </Circle>
                                </Popover>
                              </div>
                              :
                              <div className='w-full flex flex-row relative font-orbitron'>
                                <button onClick={() => handleUnstake(item.id)} className=' w-full bg-lightGray max-[1300px]:bg-customBlack lg:md:px-4 sm:px-3 px-2 py-2 font-orbitron text-yellow lg:text-3xl md:text-xl sm:text-base text-sm lg:md:rounded-2xl rounded-lg hover:bg-lightGrayHover transition duration-150 ease-out hover:ease-in'>
                                  Disconnect
                                </button>

                              </div>
                            }
                            {
                              item.canGetReward ?
                                <button onClick={() => handleGetReward(item.id)} className='w-full bg-lightGray max-[1300px]:bg-yellow lg:md:px-4 sm:px-3 px-2 py-2 font-orbitron text-yellow max-[1300px]:text-black max-[1300px]:font-bold lg:text-3xl md:text-xl sm:text-base text-sm lg:md:rounded-2xl rounded-lg hover:bg-lightGrayHover transition duration-150 ease-out hover:ease-in '>
                                  Reward Withdrawal
                                </button>
                                :
                                <div className='flex flex-row relative font-orbitron w-full'>
                                  <button disabled={true} className='w-full disabled:opacity-50 lg:md:bg-lightGray bg-lightGrayHover px-4 font-orbitron text-white lg:text-3xl md:text-xl sm:text-base text-sm rounded-2xl transition duration-150 ease-out hover:ease-in'>
                                    Reward Withdrawal
                                  </button>
                                  <Popover
                                    theme={{
                                      "base": "absolute z-20 inline-block w-max max-w-[100vw] bg-white outline-none border border-gray-200 rounded-2xl shadow-sm dark:border-gray-600 dark:bg-gray-800",
                                      "content": "w-full h-fit  border-yellow border-0 bg-lightGray rounded-2xl text-sm text-gray-500 dark:text-gray-400",
                                      "arrow": {
                                        "base": "absolute h-2 w-2 z-1 rotate-45 mix-blend-lighten bg-lightGray border border-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:mix-blend-color",
                                        "placement": "-3px"
                                      }
                                    }}
                                    content={
                                      <div className="w-fit text-white lg:max-w-[440px] max-w-[300px] h-fit lg:md:p-3 p-2 text-center border-yellow border-2 bg-lightGray rounded-xl lg:text-xl md:text-lg sm:text-base text-sm text-gray-500 dark:text-gray-400">
                                        You will be able to collect the award on {getDayNTime(item.start)}
                                      </div>
                                    }
                                    placement="top">
                                    <Circle bg='yellow' color='black' className='absolute lg:md:-top-4 -top-3 -right-4 lg:md:w-10 w-6 lg:md:h-10 h-6 cursor-pointer lg:text-2xl md:text-xl text-base hover:bg-hoverYellow'>
                                      ?
                                    </Circle>
                                  </Popover>
                                </div>
                            }
                          </div>
                        </div>

                      </div>
                    )
                  })
                    :
                    <div className="bg-lightGray rounded-[30px] flex flex-col items-center gap-10 px-6 pt-16 pb-12 max-[920px]:pt-8 max-[920px]:pb-6">
                      <div className="flex flex-col items-center gap-5">
                        <div className="font-orbitron text-white text-center text-[28px] leading-[35px] max-[920px]:text-[18px] max-[920px]:leading-[23px]">
                          <p>
                            You don't have anything yet.
                          </p>
                          <p>
                            Click "Connect node" to create your first node
                          </p>
                        </div>
                        <div className="flex flex-col gap-2">
                          <button className='yellow_btn flex flex-row items-center py-3 px-4 text-base rounded-2xl gap-3' onClick={() => handleStake()}>
                            Connect node
                            <img src={plus} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                }
              </div>
            }
            {
              (nodesStatus == 'pending') &&
              <div className='pt-10'>
                <Loader />
              </div>
            }
            {
              (nodesStatus == 'Uninitialized') &&
              <div className="bg-lightGray rounded-[30px] flex flex-col items-center lg:md:mt-10 mt-8 gap-10 px-6 pt-16 pb-12 max-[920px]:pt-8 max-[920px]:pb-6">
                <div className="flex flex-col items-center gap-5">
                  <div className="font-orbitron text-white text-center text-[28px] leading-[35px] max-[920px]:text-[18px] max-[920px]:leading-[23px]">
                    Connect your wallet to see the nodes
                  </div>
                </div>
                <div>
                  <Wallet padding='p-2' />
                </div>
              </div>
            }
          </div>
        </div>
      </div >

    </div >

  )


})

export default PacSolo
