import { providers } from "ethers";
import { NETWORKS, hexId } from "../../../scripts/utils/chains";
import { chainIds as registerdChainIds } from "../../../config";
import { useToast } from "@chakra-ui/react";

export type ChainId = (typeof registerdChainIds)[number];

export type ChangeWalletCallbackFunction = (wallet: string) => void;
export type ChangeChainCallbackFunction = (chainId: string) => void;
export type UpdateStoreStateFunction = (
  signer: providers.JsonRpcSigner,
  wallet: string,
  chainId: string
) => Promise<void>;

export class Metamask {
  public provider!: providers.Web3Provider;
  public signer!: providers.JsonRpcSigner;
  public address!: string;
  public chainId!: string;

  private metamaskProvider: any;

  constructor(
    public chainIds: readonly ChainId[],
    public defaultChainId: ChainId,
    public changeWalletCallback?: ChangeWalletCallbackFunction,
    public changeChainCallback?: ChangeChainCallbackFunction
  ) {
    const ehtProvider = (window as any).ethereum;
    this.metamaskProvider = ehtProvider;

    if (!this.metamaskProvider) {
      console.log('install mm preview');

    }
    else {
      this.metamaskProvider.on("accountsChanged", async (accounts: string) => {
        if (this.address !== accounts[0]) {
          await this.updateProviderState();
        }
        changeWalletCallback && changeWalletCallback(accounts[0]);
      });

      this.metamaskProvider.on("chainChanged", async (chainId: number) => {
        if (parseInt(this.chainId) !== chainId) {
          await this.updateProviderState();
        }
        changeChainCallback && changeChainCallback(chainId.toString());
      });

    }

  }

  async connect(): Promise<boolean> {
    const accounts = await this.metamaskProvider
      .request({ method: "eth_requestAccounts" })
      .catch((err: any) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log("Please connect to MetaMask.");
        } else {
          console.error(err);
          return false;
        }
      });
    const currentChainId = parseInt(
      await this.metamaskProvider.request({ method: "eth_chainId" }),
      16
    );
    if (this.defaultChainId.toString() != currentChainId.toString())
      await this.switchChain(this.defaultChainId);
    else {
      await this.updateProviderState();
    }
    this.address = accounts[0];
    return true;
  }

  async updateProviderState() {
    this.provider = new providers.Web3Provider(this.metamaskProvider);
    this.signer = this.provider.getSigner();
    this.address = await this.signer.getAddress();
    this.chainId = (await this.provider.getNetwork()).chainId.toString();
  }

  async switchChain(chainId: ChainId): Promise<boolean> {
    try {
      await this.metamaskProvider.request?.({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexId(Number(chainId)) }],
      });
      await this.updateProviderState();
      return true;
    } catch (error) {
      if ((error as any).code === 4902) {
        const result = this.addChain(chainId);
        if (!result) return false;
      }
      return true;
    }
  }

  async addChain(chainId: ChainId): Promise<boolean> {
    try {
      const param = {
        chainId: hexId(Number(chainId)),
        chainName: NETWORKS[chainId].name,
        nativeCurrency: {
          name: NETWORKS[chainId].symbol,
          symbol: NETWORKS[chainId].symbol,
          decimals: 18,
        },
        rpcUrls: [NETWORKS[chainId].rpc],
        blockExplorerUrls: [NETWORKS[chainId].scanner],
      };
      await this.metamaskProvider.request?.({
        method: "wallet_addEthereumChain",
        params: [param],
      });
      return true;
    } catch (addError) {
      return false;
    }
  }

  async getSigner(): Promise<any> {
    try {
      return this.provider.getSigner();
    } catch (e) {
      alert("sign " + JSON.stringify(e));
    }
  }

  async getChainId(): Promise<string> {
    return (await this.provider.getNetwork()).chainId.toString();
  }

  async getAddress(): Promise<any> {
    try {
      return (await this.getSigner()).getAddress();
    } catch (e) {
      alert("meta " + JSON.stringify(e))
    }
  }

  async disconnect(): Promise<boolean> {
    return true;
  }
}
