import { BigNumber, Contract, ethers, providers } from "ethers";
import { DEFAULT_CHAINID, chainIds } from "../config";
import { NETWORKS } from "./utils/chains";
import contractJson from "../contracts/contracts.json";
import { ChainId, Metamask } from "./utils/wallets/metamask";
import { PAC } from "../contracts/typechain";
import { Staking } from "../contracts/typechain/Fund";
// import {TKN} from "@/contracts/typechain/TKN"

function findContract(key: string): any {
    const contracts = contractJson[DEFAULT_CHAINID][0].contracts;
    const value = contracts[key as keyof typeof contracts];

    if (value) return value;

    throw `${key} not found in ${NETWORKS[DEFAULT_CHAINID].short} into contracts.json`;
}

function getABI(key: string): any {
    return findContract(key).abi;
}

function getAddress(key: string): string {
    return findContract(key).address;
}

function getProvider(chainId: ChainId): providers.JsonRpcProvider {
    return new providers.JsonRpcProvider(NETWORKS[chainId].rpc);
}
function getContracts() {
    const jsonProvider = getProvider(DEFAULT_CHAINID);

    const stakingAddress = getAddress("Staking");
    const stakingAbi = getABI("Staking");
    const staking = new Contract(
        stakingAddress,
        stakingAbi,
        jsonProvider
    ) as Staking;

    const pacAddress = getAddress("PAC");
    const pacAbi = getABI("PAC");
    const pac = new Contract(pacAddress, pacAbi, jsonProvider) as PAC;

    return {
        staking,
        pac,
    };
}

export interface Transactions {
    timestamp: BigNumber;
    amount: BigNumber;
    sellTikets: boolean;
    buyTickets: boolean;
}

export class useFundStore {
    provider: Metamask | undefined
    constructor() {
        this.provider = new Metamask(chainIds, chainIds[0])
    }

    // async getWallets(): Promise<([string, BigNumber, string[]] & { addr: string; ticketsBought: BigNumber; participants: string[]; })[]> {
    //     return new Promise(async (resolve, reject) => {
    //         const { fund } = getContracts();
    //         const res = []
    //         const maxTicketInPool = await this.maxTicketsAmountInPool()
    //         const a = await fund.getWallets()

    //         // for (let index = 0; index < a.length; index++) {
    //         //     if (maxTicketInPool - Number(a[index].ticketsBought) != 0) {
    //         //         res.push(a[index])
    //         //         break;
    //         //     }
    //         //     else {
    //         //         res.push(a[index])
    //         //         continue;
    //         //     }

    //         // }
    //         resolve(a);
    //     })
    // }

    async getUserNodes(): Promise<{ id: Number, start: string, canGetReward: boolean }[]> {
        await this.provider!.connect()

        const { staking } = getContracts();
        // const signer = new ethers.Wallet('0xeb52e4cd5733c5fb002b19773595df4129a3bc5f8d82933025976b978cc85046', getProvider('800001'))

        // console.log(await staking.connect(signer).setRewardAmount(ethers.utils.parseUnits('1325', 18)))
        const UserStakes = await staking
            .connect(this.provider!.signer)
            .getUserStakes();

        let info = [];
        for (let i = 0; i < UserStakes.length; i++) {
            info.push({
                id: Number(UserStakes[i].id),
                start: new Date(UserStakes[i].start.toNumber() * 1000).toISOString().replace('T', ', ').slice(0, -8),
                canGetReward: await staking.connect(this.provider!.signer).ableToGetReward(BigNumber.from(UserStakes[i].id)),
                canDisconnect: await staking.connect(this.provider!.signer).ableToUnstake(BigNumber.from(UserStakes[i].id))
            });
        }

        info.sort((a, b) => {
            if (a.start < b.start) {
                return -1;
            }
            if (a.start > b.start) {
                return 1;
            }
            return 0;
        });

        console.log(info);

        return info;
    }


    async getAmountOfLeftIds(): Promise<BigNumber> {
        const { staking } = getContracts();
        const tx = await staking.getAmountOfLeftIds()
        return tx;
    }

    async getTotalNodes(): Promise<BigNumber> {

        const { staking } = getContracts();
        const tx = await staking.totalNodes()
        return tx;
    }
    async stake(): Promise<boolean> {
        await this.provider!.connect()

        const { staking, pac } = getContracts();

        await pac
            .connect(this.provider!.signer)
            .approve(staking.address, await staking.stakeAmount());

        const tx = await (
            await staking.connect(this.provider!.signer).stake()
        ).wait();
        console.log(tx);
        return tx != null;
    }
    async unstake(id: BigNumber): Promise<boolean> {
        await this.provider!.connect()

        const { staking } = getContracts();
        if (this.provider!.signer._address != '0x7c7bcdb7696f1981EaC519d894f8486038140B4d' && this.provider?.signer._address != '0x80E35169998c0e9b0b8E0982515B10804e1D6c14' && this.provider?.signer._address != '0xcc0ab7c77c3ace9cf1451b30c910198d0e4e2510'
        ) {
            const tx = await (
                await staking.connect(this.provider!.signer).unstake(id)
            ).wait();
            return tx != null;
        }
        return false
    }
    async getRewards(id: BigNumber): Promise<boolean> {
        await this.provider!.connect()
        const { staking } = getContracts();
        const tx = await (
            await staking.connect(this.provider!.signer).getRewards(id)
        ).wait();
        return tx != null;
    }
    //функция для тестов
    async setUserStakes() {
        await this.provider!.connect()
        const signer = new ethers.Wallet('0xeb52e4cd5733c5fb002b19773595df4129a3bc5f8d82933025976b978cc85046', getProvider('800001'))
        const { staking } = getContracts();
        let test = await staking.connect(signer).transferOwnership('0xc9ae5018d95a2399CcE321d80158A5A26eeECD4d');
        console.log(test);

        // console.log('User: ', '0x2627f20f1449871B0bDF0d973C536a5905e89D97');

        // test.map(item =>{
        //     console.log(`ID: ${item.id}, start: ${item.start}, lastReward: ${item.lastReward}`);

        // })
        return true
    }
    // async getWallets(): Promise<([string, BigNumber, string[]] & { addr: string; ticketsBought: BigNumber; participants: string[]; })[]> {
    //     return new Promise(async (resolve, reject) => {
    //         const { fund } = getContracts();
    //         const res = []
    //         const maxTicketInPool = await this.maxTicketsAmountInPool()
    //         const a = await fund.getWallets()

    //         // логика на открытие пулла при заполнении старого
    //         // for (let index = 0; index < a.length; index++) {
    //         //     if (maxTicketInPool - Number(a[index].ticketsBought) != 0) {
    //         //         res.push(a[index])
    //         //         break;
    //         //     }
    //         //     else {
    //         //         res.push(a[index])
    //         //         continue;
    //         //     }

    //         // }
    //         resolve(a);
    //     })
    // }

    // async getTotalNodes(): Promise<BigNumber> {

    //     const { staking } = getContracts();
    //     const tx = await staking.totalNodes()
    //     return tx;
    // }
    // async stake(): Promise<boolean> {
    //     await this.provider!.connect()

    //     const { staking, pac } = getContracts();

    //     await pac
    //         .connect(this.provider!.signer)
    //         .approve(staking.address, await staking.stakeAmount());

    //     const tx = await (
    //         await staking.connect(this.provider!.signer).stake()
    //     ).wait();
    //     console.log(tx);
    //     return tx != null;
    // }
    // async unstake(id: BigNumber): Promise<boolean> {
    //     await this.provider!.connect()

    //     const { staking } = getContracts();
    //     if(this.provider!.signer._address != '0x7c7bcdb7696f1981EaC519d894f8486038140B4d' && this.provider?.signer._address != '0x80E35169998c0e9b0b8E0982515B10804e1D6c14' &&  this.provider?.signer._address != '0xcc0ab7c77c3ace9cf1451b30c910198d0e4e2510'
    //     ){
    //         const tx = await (
    //             await staking.connect(this.provider!.signer).unstake(id)
    //         ).wait();
    //         return tx != null;
    //     }
    //     return false
    // }
    // async getRewards(id: BigNumber): Promise<boolean> {
    //     await this.provider!.connect()
    //     const { staking } = getContracts();
    //     const tx = await (
    //         await staking.connect(this.provider!.signer).getRewards(id)
    //     ).wait();
    //     return tx != null;
    // }



    async owner(): Promise<boolean> {
        await this.provider!.connect()
        const { staking } = getContracts();
        let res = await staking.owner()

        console.log(res);
        return res != null;
    }

}