interface network {
  rpc: string;
  moralis: string;
  chainId: number;
  scanner: string;
  name: string;
  symbol: string;
  short: string;
  type: "mainnet" | "testnet";
}

type MapType = {
  [token: string]: network;
};

const networks: MapType = {
  bsc_testnet: {
    rpc: "https://data-seed-prebsc-1-s1.binance.org:8545",
    moralis: "/bsc/testnet",
    chainId: 97,
    scanner: "https://testnet.bscscan.com/",
    name: "Binance Smart Chain Testnet",
    symbol: "BNB",
    short: "bsc_testnet",
    type: "testnet",
  },
  localhost: {
    rpc: "http://127.0.0.1:8545/",
    moralis: "/",
    chainId: 31337,
    scanner: "/",
    name: "Localhost",
    symbol: "L",
    short: "localhost",
    type: "testnet",
  },
  octa: {
    rpc: "https://rpc.octa.space",
    moralis: "/",
    chainId: 800001,
    scanner: "https://explorer.octa.space/",
    name: "OctaSpace",
    symbol: "OCTA",
    short: "octa",
    type: "mainnet",
  },
  redev2: {
    rpc: "https://rpc2.redecoin.eu/",
    moralis: "/",
    chainId: 1972,
    scanner: "https://explorer3.redecoin.eu/",
    name: "redev2",
    symbol: "REDEV2",
    short: "redev2",
    type: "mainnet",
  },
};

type MapNum = {
  [token: number]: network;
};

export const NETWORKS: MapNum = {
  "97": networks.bsc_testnet,
  "33137": networks.localhost,
  "800001": networks.octa,
  "1972": networks.redev2,
};

export function hexId(chainId: number) {
  return "0x" + chainId.toString(16);
}